import React, { useEffect, useState, useRef } from 'react'
import { initializeApp } from "firebase/app";
import { confirmPasswordReset, getAuth, verifyPasswordResetCode } from "firebase/auth";
import ResetForm from './components/ResetForm';
import ErrorPage from './components/ErrorPage'
import './App.css'
import logo from './assets/logo.png'



const App = () => {
  const [validUrl, setValidUrl] = useState(true)

  let auth = useRef(null)
  let actionCode = useRef(null)

  useEffect( () => {
    // Get the action to complete.
    const mode = getParameterByName('mode');
    // Get the one-time code from the query parameter.
    actionCode.current = getParameterByName('oobCode');
  
    if (mode === "" || mode === null || actionCode === "" || actionCode === null) {
      //redirect to 404 page
      sendTo404()
    }

    // Configure the Firebase SDK.
    // This is the minimum configuration required for the API to be used.
    const config = {
      'apiKey': "AIzaSyBZbUIZgPbTxq6iz0E7FuNIebRIOGmG-FY"
    };
  
    const app = initializeApp(config);
    auth.current = getAuth(app);
  
    //Only allow resetPassword mode
    if (mode !== 'resetPassword') {
      sendTo404()
    }

  }, [])

  function getParameterByName(search) {
    let url_string = window.location.href
    let url = new URL(url_string)
    let retVal = url.searchParams.get(search)
    return retVal
  }


  function handleResetPassword(newPassword) {    
    console.log("Let's handle it! " + newPassword)
    verifyPasswordResetCode(auth.current, actionCode.current)
    .then((email) => {
      console.log(email)
      confirmPasswordReset(auth.current, actionCode.current, newPassword)
      .then((resp) => {
        //Display Success
        console.log(resp)
      }).catch((error) => {
        console.log(error)
        alert("Error resetting password")
      })
    }).catch((error) => {
      console.log(error)
      alert("Password Reset Code Expired.\nPlease return to app and try again")
    })
  }
  
  function sendTo404() {
    setValidUrl(false)
  }

  return (
    <div className="container">
      <header className="app_header">
        <img src={logo} alt="Logo" className="app_logo" />
        <h1>Base App</h1>
      </header>
      <div className="app_body">
        { validUrl ? 
          <>
            <p>Please use the form to reset your password</p>
            <ResetForm handleSubmit={handleResetPassword} />
          </> : 
          <ErrorPage />
        }
        
      </div>
    </div>
  );
}

export default App;
