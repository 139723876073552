import React from 'react'

function ErrorPage() {
  return (
    <div className="reset_form">
      <h2>Oh No!</h2>
      <h3>The page you requested cannot&nbsp;be&nbsp;found</h3>
    </div>
  )
}

export default ErrorPage