import React, { useState } from 'react'

const ResetForm = ({handleSubmit}) => {
    const [newPassword, setNewPassword] = useState('')
    const [newPassword2, setNewPassword2] = useState('')

    function onSubmit(e) {
        e.preventDefault()
        
        //check passwords match
        if (newPassword !== newPassword2) {
            alert("Error!\nPasswords do not match!")
            setNewPassword('')
            setNewPassword2('')
        } else {
        //handleSubmit
        handleSubmit(newPassword)
        }
    }

  return (
    <form className="newPasswordForm" onSubmit={onSubmit}> 
        <div className="reset_form">
            <input type="password" className="form_input"
                placeholder="New Password" value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)} />
            <input type="password" className="form_input"
                placeholder="Confirm Password" value={newPassword2}
                onChange={(e) => setNewPassword2(e.target.value)} />
            <button className="submit_button" name="submit_button">Submit</button>
        </div>
    </form>
  )
}

export default ResetForm